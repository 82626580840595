import styled from 'styled-components';
import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import CalculatorType from './CalculatorType';
import CreatorCalculator from './CreatorCalculator';
import FormulaTip from './FormulaTip';
import ManufacturerCalculator from './ManufacturerCalculator';
import CurrencySelect, { Currency, CurrencyContext } from './CurrencySelect';
import { Theme } from '../../utils/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Theme.Breakpoints.MD}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CalculatorTypeWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const DirectWrapper = styled.div`
  margin-top: 1rem;
`;

const Calculator: FC = () => {
  const { t } = useTranslation();
  const [isCreator, setIsCreator] = useState<boolean>(true);
  const [isDirect, setIsDirect] = useState<boolean>(false);
  const [currency, setCurrency] = useState<string>('USD');

  const data = useStaticQuery(
    graphql`
      query {
        allCurrency {
          nodes {
            name
            isoCode
            symbol
          }
        }
      }
    `
  );
  const currencies = data.allCurrency.nodes as Currency[];
  const defaultCurrency = currencies.find(c => c.isoCode === currency);

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value === 'creator' ? setIsCreator(true) : setIsCreator(false);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(e.target.value);
  };

  return (
    <Form>
      <Wrapper>
        <CalculatorTypeWrapper>
          <CalculatorType selected={isCreator ? 'creator' : 'manufacturer'} onTypeChange={handleTypeChange} />
          {isCreator ? (
            <>
              <DirectWrapper>
                <Form.Check
                  custom
                  id="direct"
                  type="checkbox"
                  label={<FormulaTip content={t('page.calculator.no_wholesale')} text={t('page.calculator.direct')} />}
                  checked={isDirect}
                  onChange={() => setIsDirect(!isDirect)}
                />
              </DirectWrapper>
            </>
          ) : null}
        </CalculatorTypeWrapper>
        <CurrencySelect currency={currency} onChange={handleCurrencyChange} />
      </Wrapper>

      <CurrencyContext.Provider value={defaultCurrency}>
        {isCreator ? <CreatorCalculator isDirect={isDirect} /> : <ManufacturerCalculator />}
      </CurrencyContext.Provider>
    </Form>
  );
};

export default Calculator;
