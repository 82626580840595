import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type CalculatorTypeProps = {
  selected: 'creator' | 'manufacturer';
  onTypeChange: React.FormEventHandler<HTMLInputElement>;
};

const CalculatorType: FC<CalculatorTypeProps> = ({ selected, onTypeChange }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Check
        custom
        inline
        id="creator"
        type="radio"
        name="merchant"
        value="creator"
        label={t('page.calculator.creator')}
        checked={selected === 'creator'}
        onChange={onTypeChange}
      />
      <Form.Check
        custom
        inline
        id="manufacturer"
        name="merchant"
        value="manufacturer"
        type="radio"
        label={t('page.calculator.manufacturer')}
        checked={selected === 'manufacturer'}
        onChange={onTypeChange}
      />
    </div>
  );
};
export default CalculatorType;
