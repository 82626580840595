import styled from 'styled-components';
import React, { FC } from 'react';
import { Col, Form, FormControlProps, Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from 'gatsby';

import { Theme } from '../../utils/theme';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const StyledFormGroup = styled(Form.Group)`
  margin-top: 0;
  margin-bottom: 1rem;

  @media (min-width: ${Theme.Breakpoints.MD}) {
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

export type Currency = {
  isoCode: string;
  name: string;
  symbol: string;
};

export const CurrencyContext = React.createContext<Currency | undefined>(undefined);

type CurrencySelectProps = {
  currency: string;
} & FormControlProps;

const CurrencySelect: FC<CurrencySelectProps> = ({ currency, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allCurrency {
          nodes {
            name
            isoCode
            symbol
          }
        }
      }
    `
  );
  const currencies = data.allCurrency.nodes as Currency[];
  const { t } = useTranslation();

  return (
    <StyledFormGroup as={Row}>
      <Form.Label column md="auto" style={{ paddingRight: 0 }}>
        {t('page.calculator.currency')}
      </Form.Label>
      <Col>
        <Form.Control {...rest} as="select" custom style={{ width: '300px' }} value={currency}>
          {currencies.map(c => (
            <option key={c.isoCode} value={c.isoCode}>
              {c.isoCode} - {c.name} ({c.symbol})
            </option>
          ))}
        </Form.Control>
      </Col>
    </StyledFormGroup>
  );
};
CurrencySelect.defaultProps = {
  currency: 'USD',
};

export default CurrencySelect;
