import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { darken } from 'polished';

import { Languages } from '../../utils/languages';
import { Theme } from '../../utils/theme';

const LogoLink = styled(Link)`
  display: block;
`;

const Logo: FC = () => (
  <LogoLink to="/">
    <img src="/images/logo.png" alt="Logo" height={80} />
  </LogoLink>
);

const ButtonNavLink = styled(Nav.Link)`
  &&& {
    background-color: ${props => props.color};
    color: #fff;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-left: 0.5rem;

    &:hover {
      color: #fff;
      background-color: ${props => darken(0.1, props.color)};
    }
  }
`;

const navLinkStyles = css`
  color: ${Theme.Colors.TEXT};
  &:hover {
    color: ${Theme.Colors.PRIMARY};
  }
`;

const StyledNavLink = styled(Nav.Link)`
  &&& {
    ${navLinkStyles}
  }
`;

const StyledLink = styled(Link)`
  &&& {
    ${navLinkStyles}
  }
`;

const StyledNavbar = styled(Navbar)`
  padding: 0.7rem 0;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledDropdown = styled(NavDropdown)`
  &&& {
    .dropdown-toggle {
      color: ${Theme.Colors.TEXT};
    }
    .dropdown-toggle:hover {
      color: ${Theme.Colors.PRIMARY};
    }
  }
`;

type TitleProps = {
  locale: string;
};

const Title: FC<TitleProps> = ({ locale }) => {
  const lang = Languages.find(l => l.locale === locale);
  return <span>{lang?.name}</span>;
};

const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const [locale, setLocale] = useState<string>(i18n.language);
  const currentLang = <Title locale={locale} />;

  useEffect(() => {
    setLocale(i18n.language);
  }, [i18n.language]);

  return (
    <StyledDropdown title={currentLang} id="language-dropdown">
      {Languages.map(lang => (
        <NavDropdown.Item
          active={lang.locale === locale}
          key={lang.locale}
          href="#"
          onClick={() => {
            changeLanguage(lang.locale);
          }}>
          {lang.name}
        </NavDropdown.Item>
      ))}
    </StyledDropdown>
  );
};

const Header: FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <StyledNavbar expand="lg">
      <Navbar.Brand as={Logo}>React-Bootstrap</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <LanguageDropdown />
          <StyledLink to="/pricing" className="nav-link">
            {t('pricing', 'Pricing')}
          </StyledLink>
          <StyledLink to="/support" className="nav-link">
            {t('support', 'Support')}
          </StyledLink>
          <ButtonNavLink href={`${process.env.GATSBY_LOGIN_URL}?locale=${locale}`} color={Theme.Colors.GRAY}>
            {t('signin', 'Sign In')}
          </ButtonNavLink>
          <ButtonNavLink href={`${process.env.GATSBY_JOIN_URL}?locale=${locale}`} color={Theme.Colors.ORANGE}>
            {t('join', 'Join')}
          </ButtonNavLink>
          <ButtonNavLink
            href={`${process.env.GATSBY_BOOK_DEMO_URL}?locale=${locale}`}
            style={{ fontWeight: 700 }}
            color="#1975d1">
            {t('book_a_demo', 'Book A Demo')}
          </ButtonNavLink>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar>
  );
};

export default Header;
