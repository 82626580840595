import accounting from 'accounting';

// ------------------------------------------
// MARGIN
// ------------------------------------------
export const calcMarginPrice = (cost: number, margin: number) => {
  const diff = 1 - decimalPercent(margin);
  if (diff === 0) return 0;
  return toAmount(toAmount(cost) / diff);
};

export const calcMargin = (cost: number, price: number) => {
  if (price === 0) return 0;
  const diff = cost / price;
  return toPercent(1 - diff);
};

// ------------------------------------------
// MARKUP
// ------------------------------------------
export const calcMarkupPrice = (cost: number, markup: number) => {
  const diff = decimalPercent(parseFloat(markup.toString()) + 100);
  return toAmount(toAmount(cost) * diff);
};

export const calcMarkup = (cost: number, price: number) => {
  const diff = toAmount(cost) - 1;
  if (diff === 0) return 0;
  return toPercent(toAmount(price) / diff);
};

// ------------------------------------------
// FORMATTER
// ------------------------------------------
export const toAmount = (
  amount: number | string | null | undefined
): number => {
  const formatted = accounting.toFixed(
    parseFloat(Number(amount).toString()),
    2
  );
  return parseFloat(formatted);
};

export const decimalPercent = (percent: number) =>
  percent ? parseFloat(percent.toString()) / 100 : 0;

export const toPercent = (value: number) =>
  value ? toAmount(parseFloat(value.toString()) * 100) : 0.0;
