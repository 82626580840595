import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import FormulaTip from './FormulaTip';
import PercentageCalc from './PercentageCalc';
import SumCalc from './SumCalc';
import TextDivider from './TextDivider';

const ManufacturerCalculator: FC = () => {
  const { t } = useTranslation();
  const [totalCost, setTotalCost] = useState<number>(0);
  const [wholePrice, setWholePrice] = useState<number>(0);
  return (
    <>
      <TextDivider text={t('page.calculator.manu_cost')} />
      <SumCalc
        labelCost={t('page.calculator.cost')}
        labelFreight={t('page.calculator.freight')}
        labelImport={t('page.calculator.export')}
        labelTotal={t('page.calculator.cost_summary')}
        totalCost={totalCost}
        setTotalCost={setTotalCost}
      />

      <PercentageCalc
        labelTotalCost={t('page.calculator.cost_summary')}
        labelMarkup={
          <FormulaTip
            title={t('page.calculator.markup_formula')}
            content="Total Manufacturing Cost (MCOGS) = Cost Summary (CS) * (1 + Markup %)"
            text={t('page.calculator.markup')}
          />
        }
        labelPrice={t('page.calculator.mcogs')}
        labelProfit={t('page.calculator.gross_profit')}
        labelMargin={t('page.calculator.gross_margin')}
        initCost={totalCost}
        initPrice={wholePrice}
        price={wholePrice}
        setPrice={setWholePrice}
      />
    </>
  );
};

export default ManufacturerCalculator;
