import styled from 'styled-components';
import React, { FC, FormEvent, useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faPlus } from '@fortawesome/free-solid-svg-icons';

import MoneyInput from './MoneyInput';
import { CurrencyContext } from './CurrencySelect';
import { Theme } from '../../utils/theme';
import { toAmount } from './calc';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${Theme.Breakpoints.MD}) {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const Sign = styled.div`
  text-align: center;
  @media (min-width: ${Theme.Breakpoints.MD}) {
    position: relative;
    bottom: 20px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

type SumCalcProps = {
  labelCost: string;
  labelFreight: string;
  labelImport: string;
  labelTotal: string;
  totalCost: number;
  setTotalCost: (total: number) => void;
};

const SumCalc: FC<SumCalcProps> = ({
  labelCost,
  labelFreight,
  labelImport,
  labelTotal,
  totalCost,
  setTotalCost
}) => {
  const [cost, setCost] = useState<number>(0);
  const [freight, setFreight] = useState<number>(0);
  const [importing, setImporting] = useState<number>(0);

  const currency = useContext(CurrencyContext);
  const currencyPrefix = `${currency?.isoCode}${currency?.symbol}`;

  const handleCostChange = (event: FormEvent<HTMLInputElement>) => {
    const amount = Number(event.currentTarget.value);
    setCost(toAmount(amount));
    setTotalCost(toAmount(amount + freight + importing));
  };

  const handleFreightChange = (event: FormEvent<HTMLInputElement>) => {
    const amount = Number(event.currentTarget.value);
    setFreight(toAmount(amount));
    setTotalCost(toAmount(cost + amount + importing));
  };

  const handleImportChange = (event: FormEvent<HTMLInputElement>) => {
    const amount = Number(event.currentTarget.value);
    setImporting(toAmount(amount));
    setTotalCost(toAmount(cost + freight + amount));
  };

  return (
    <Wrapper>
      <MoneyInput
        prefix={currencyPrefix}
        label={labelCost}
        value={cost}
        onChange={handleCostChange}
        />
      <Sign>
        <FontAwesomeIcon icon={faPlus} width="16" />
      </Sign>
      <MoneyInput
        prefix={currencyPrefix}
        label={labelFreight}
        value={freight}
        onChange={handleFreightChange}
        />
      <Sign>
        <FontAwesomeIcon icon={faPlus} width="16" />
      </Sign>
      <MoneyInput
        prefix={currencyPrefix}
        label={labelImport}
        value={importing}
        onChange={handleImportChange}
        />
      <Sign>
        <FontAwesomeIcon icon={faEquals} width="16" />
      </Sign>
      <MoneyInput
        prefix={currencyPrefix}
        label={labelTotal}
        value={totalCost}
        readOnly
        canCopy
        />
    </Wrapper>
  );
};

export default SumCalc;
