import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import React, { FC, useState, useEffect } from 'react';
import { Button, Form, Modal, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Poster = styled.div`
  display: block;
`;

const ActionWrapper = styled.div`
  margin-top: 1rem;
`;

const StyledModalBody = styled.div`
  display: flex;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const DownloadButton: FC = () => {
  const { t, i18n } = useTranslation();

  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const [submitting, setSubmitting] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    register({ name: 'captchaToken' }, { required: true });
  });

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    await axios.post(
      `${process.env.GATSBY_API_URL!}/pricing_sheets/download`,
      Object.assign(values, { locale: i18n.language })
    );
    setDownloaded(true);
    setSubmitting(false);
  };

  const onCaptchaSuccess = (token: string | null) => {
    setValue('captchaToken', token);
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleShowDialog}>
        {t('page.cheat_sheet.download')}
      </Button>
      <Modal show={showDialog} onHide={handleCloseDialog} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('page.cheat_sheet.title')}</Modal.Title>
        </Modal.Header>
        <StyledModalBody>
          <FormWrapper>
            <div>
              <img src="/images/logo.png" alt="Logo" height={80} />
            </div>
            <p className="mt-4">{t('page.cheat_sheet.desc')}</p>
            {downloaded ? (
              <Alert variant="info">{t('page.cheat_sheet.downloaded')}</Alert>
            ) : (
              <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <Form.Group>
                  <input
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    ref={register({
                      required: t('error.required') as string,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('error.invalid_email'),
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      {errors.email.message}
                    </div>
                  )}
                </Form.Group>
                <ReCAPTCHA
                  sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY as string}
                  onChange={onCaptchaSuccess}
                  hl={i18n.language}
                />
                {errors.captchaToken && (
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    {t('error.unverify_captcha')}
                  </div>
                )}
                <ActionWrapper>
                  <Button variant="light" onClick={handleCloseDialog}>
                    {t('page.cheat_sheet.no_button')}
                  </Button>
                  <Button variant="primary" type="submit" className="ml-3" disabled={submitting}>
                    {t('page.cheat_sheet.yes_button')}
                  </Button>
                </ActionWrapper>
              </Form>
            )}
          </FormWrapper>
          <Poster>
            {downloaded ? (
              <img src="/images/sheet-poster-downloaded.jpg" alt="Poster" style={{ width: '100%' }} />
            ) : (
              <img src="/images/sheet-poster.jpg" alt="Poster" style={{ width: '100%' }} />
            )}
          </Poster>
        </StyledModalBody>
      </Modal>
    </>
  );
};

export default DownloadButton;
