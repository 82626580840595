export const Languages = [
  { name: 'English', val: 'English', locale: 'en' },
  { name: 'বাংলা (baɛṅlā)', val: 'Bengali', locale: 'bn' },
  { name: '中文 (简体)', val: 'Mandarin', locale: 'zh-tw' },
  { name: '中文 (繁體)', val: 'Cantonese', locale: 'zh-cn' },
  { name: 'Francais', val: 'French', locale: 'fr' },
  { name: 'Deutsch', val: 'German', locale: 'de' },
  { name: 'हिन्दी (hindī)', val: 'Hindu', locale: 'hi' },
  { name: 'Bahasa Indonesia', val: 'Indonesia', locale: 'id' },
  { name: 'Italiano', val: 'Italian', locale: 'it' },
  { name: '日本語', val: 'Japanese', locale: 'ja' },
  { name: '한국어', val: 'Korean', locale: 'ko' },
  { name: 'Portugues', val: 'Portuguese', locale: 'pt' },
  { name: 'Espanol', val: 'Spanish', locale: 'es' },
  { name: 'Türkçe', val: 'Turkey', locale: 'tr' },
  { name: 'Tiếng Việt', val: 'Vietnamese', locale: 'vi' },
];
