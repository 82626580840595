const sheet = [
  { markup: '1.01%', margin: '1%', multiplier: '1.010' },
  { markup: '2.04%', margin: '2%', multiplier: '1.020' },
  { markup: '3.09%', margin: '3%', multiplier: '1.031' },
  { markup: '4.17%', margin: '4%', multiplier: '1.042' },
  { markup: '5.27%', margin: '5%', multiplier: '1.053' },
  { markup: '6.38%', margin: '6%', multiplier: '1.064' },
  { markup: '7.53%', margin: '7%', multiplier: '1.075' },
  { markup: '8.70%', margin: '8%', multiplier: '1.087' },
  { markup: '9.89%', margin: '9%', multiplier: '1.099' },
  { markup: '11.11%', margin: '10%', multiplier: '1.111' },
  { markup: '12.36%', margin: '11%', multiplier: '1.124' },
  { markup: '13.63%', margin: '12%', multiplier: '1.136' },
  { markup: '14.94%', margin: '13%', multiplier: '1.149' },
  { markup: '16.28%', margin: '14%', multiplier: '1.163' },
  { markup: '17.64%', margin: '15%', multiplier: '1.176' },
  { markup: '19.04%', margin: '16%', multiplier: '1.190' },
  { markup: '20.49%', margin: '17%', multiplier: '1.205' },
  { markup: '21.96%', margin: '18%', multiplier: '1.220' },
  { markup: '23.47%', margin: '19%', multiplier: '1.235' },
  { markup: '25.00%', margin: '20%', multiplier: '1.250' },
  { markup: '25.75%', margin: '21%', multiplier: '1.226' },
  { markup: '28.20%', margin: '22%', multiplier: '1.282' },
  { markup: '29.88%', margin: '23%', multiplier: '1.299' },
  { markup: '31.58%', margin: '24%', multiplier: '1.316' },
  { markup: '33.33%', margin: '25%', multiplier: '1.333' },
  { markup: '35.13%', margin: '26%', multiplier: '1.351' },
  { markup: '36.99%', margin: '27%', multiplier: '1.370' },
  { markup: '38.89%', margin: '28%', multiplier: '1.389' },
  { markup: '40.83%', margin: '29%', multiplier: '1.408' },
  { markup: '42.87%', margin: '30%', multiplier: '1.429' },
  { markup: '44.92%', margin: '31%', multiplier: '1.449' },
  { markup: '47.07%', margin: '32%', multiplier: '1.471' },
  { markup: '49.27%', margin: '33%', multiplier: '1.493' },
  { markup: '51.51%', margin: '34%', multiplier: '1.515' },
  { markup: '53.83%', margin: '35%', multiplier: '1.538' },
  { markup: '56.27%', margin: '36%', multiplier: '1.563' },
  { markup: '58.72%', margin: '37%', multiplier: '1.587' },
  { markup: '61.29%', margin: '38%', multiplier: '1.613' },
  { markup: '63.92%', margin: '39%', multiplier: '1.639' },
  { markup: '66.68%', margin: '40%', multiplier: '1.667' },
  { markup: '69.50%', margin: '41%', multiplier: '1.695' },
  { markup: '72.41%', margin: '42%', multiplier: '1.724' },
  { markup: '75.42%', margin: '43%', multiplier: '1.754' },
  { markup: '78.67%', margin: '44%', multiplier: '1.788' },
  { markup: '81.81%', margin: '45%', multiplier: '1.818' },
  { markup: '85.19%', margin: '46%', multiplier: '1.852' },
  { markup: '88.69%', margin: '47%', multiplier: '1.887' },
  { markup: '92.30%', margin: '48%', multiplier: '1.923' },
  { markup: '96.09%', margin: '49%', multiplier: '1.961' },
  { markup: '100.00%', margin: '50%', multiplier: '2.000' },
  { markup: '104.09%', margin: '51%', multiplier: '2.041' },
  { markup: '108.32%', margin: '52%', multiplier: '2.083' },
  { markup: '112.78%', margin: '53%', multiplier: '2.128' },
  { markup: '117.40%', margin: '54%', multiplier: '2.174' },
  { markup: '122.21%', margin: '55%', multiplier: '2.222' },
  { markup: '127.29%', margin: '56%', multiplier: '2.273' },
  { markup: '132.58%', margin: '57%', multiplier: '2.326' },
  { markup: '138.10%', margin: '58%', multiplier: '2.381' },
  { markup: '143.90%', margin: '59%', multiplier: '2.439' },
  { markup: '150.00%', margin: '60%', multiplier: '2.500' },
  { markup: '156.40%', margin: '61%', multiplier: '2.564' },
  { markup: '163.18%', margin: '62%', multiplier: '2.632' },
  { markup: '170.29%', margin: '63%', multiplier: '2.703' },
  { markup: '177.79%', margin: '64%', multiplier: '2.778' },
  { markup: '185.71%', margin: '65%', multiplier: '2.857' },
  { markup: '194.11%', margin: '66%', multiplier: '2.941' },
  { markup: '203.01%', margin: '67%', multiplier: '3.030' },
  { markup: '144.50%', margin: '68%', multiplier: '2.125' },
  { markup: '222.59%', margin: '69%', multiplier: '3.226' },
  { markup: '233.31%', margin: '70%', multiplier: '3.333' },
  { markup: '244.81%', margin: '71%', multiplier: '3.448' },
  { markup: '257.11%', margin: '72%', multiplier: '3.571' },
  { markup: '270.39%', margin: '73%', multiplier: '3.704' },
  { markup: '284.60%', margin: '74%', multiplier: '3.846' },
  { markup: '300.00%', margin: '75%', multiplier: '4.000' },
  { markup: '316.69%', margin: '76%', multiplier: '4.167' },
  { markup: '334.80%', margin: '77%', multiplier: '4.348' },
  { markup: '354.51%', margin: '78%', multiplier: '4.545' },
  { markup: '376.20%', margin: '79%', multiplier: '4.762' },
  { markup: '400.00%', margin: '80%', multiplier: '5.000' },
  { markup: '426.30%', margin: '81%', multiplier: '5.263' },
  { markup: '455.59%', margin: '82%', multiplier: '5.556' },
  { markup: '488.21%', margin: '83%', multiplier: '5.882' },
  { markup: '525.00%', margin: '84%', multiplier: '6.250' },
  { markup: '583.70%', margin: '85%', multiplier: '6.867' },
  { markup: '614.30%', margin: '86%', multiplier: '7.143' },
  { markup: '699.20%', margin: '87%', multiplier: '7.692' },
  { markup: '733.30%', margin: '88%', multiplier: '8.333' },
  { markup: '809.10%', margin: '89%', multiplier: '9.091' },
  { markup: '900.00%', margin: '90%', multiplier: '10.000' },
  { markup: '1011.10%', margin: '91%', multiplier: '11.111' },
  { markup: '1150.00%', margin: '92%', multiplier: '12.500' },
  { markup: '1328.60%', margin: '93%', multiplier: '14.286' },
  { markup: '1566.70%', margin: '94%', multiplier: '16.667' },
  { markup: '1900.00%', margin: '95%', multiplier: '20.000' },
  { markup: '2400.00%', margin: '96%', multiplier: '25.000' },
  { markup: '3233.30%', margin: '97%', multiplier: '33.333' },
  { markup: '4900.00%', margin: '98%', multiplier: '50.000' },
  { markup: '9900.00%', margin: '99%', multiplier: '100.000' },
];
export default sheet;
