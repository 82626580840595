import styled from 'styled-components';
import React, { FC } from 'react';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 30px;
  border-bottom: 1px solid #dee2e6;
  line-height: 0.1em;
  margin: 10px 0 20px;

  span {
    background: #fff;
    padding: 0 10px;
    font-weight: 500;
  }
`;

type TextDividerProps = {
  text: string;
};

const TextDivider: FC<TextDividerProps> = ({ text }) => 
  <Wrapper>
    <span>{text}</span>
  </Wrapper>
;

export default TextDivider;
