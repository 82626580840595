export const Theme = {
  Colors: {
    PRIMARY: '#1975d1',
    GRAY: '#455A65',
    ORANGE: '#ee3900',
    PURPLE: '#7f2398',
    TEXT: '#212529',
    BORDER: '#dbdbdb',
  },
  Breakpoints: {
    XS: 0,
    SM: '576px',
    MD: '768px',
    LG: '992px',
    XL: '1200px',
  },
};
