/* eslint-disable no-extra-parens */
import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type FormulaTipProps = {
  title?: string;
  content: string;
  text: string;
};

const FormulaTip: FC<FormulaTipProps> = ({ title, content, text }) => {
  const popover = (
    <Popover id="popover-wholesale">
      {title ? <Popover.Title as="h3">{title}</Popover.Title> : null}
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );

  return (
    <>
      <span className="mr-3">{text}</span>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={popover}>
        <FontAwesomeIcon icon={faInfoCircle} width="16" />
      </OverlayTrigger>
    </>
  );
};

export default FormulaTip;
