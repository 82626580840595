import styled from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import { Button, Container, Modal, Tab, Tabs } from 'react-bootstrap';
import { PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Calculator from '../components/calculator';
import CheatSheet from '../components/cheatsheet';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Heading = styled.h3`
  margin-bottom: 1rem;
`;

const StyledModalBody = styled.div`
  display: flex;
`;

const Poster = styled.div`
  display: block;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const ActionWrapper = styled.div`
  margin-top: 1rem;
`;

const CalculatorPage: FC<PageProps> = ({ location }) => {
  const { t } = useTranslation();
  // const query = queryString.parse(location.search);
  // const queryTab = query.tab as string || 'calculator';
  const [tab, setTab] = useState<string>('calculator');

  const handleSelectTab = (eventKey: any) => {
    setTab(eventKey as string);
  };

  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  useEffect(() => {
    const queryTab = location.hash === '#cheatsheet' ? 'cheatsheet' : 'calculator';
    setTab(queryTab as string);
  }, [setTab]);

  useEffect(() => {
    const handleCopied = () => {
      handleShowDialog();
    };
    window.addEventListener('mf-copied', handleCopied);
    return () => {
      window.removeEventListener('mf-copied', handleCopied);
    };
  });

  return (
    <Layout>
      <SEO article title={t('page.calculator.meta.title')} description={t('page.calculator.meta.description')} />
      <Container>
        <Heading>{t('page.calculator.heading')}</Heading>
        <p>{t('page.calculator.blurb')}</p>
        <hr />
        <Tabs id="calculator-tab" activeKey={tab} onSelect={handleSelectTab}>
          <Tab eventKey="calculator" title={t('page.calculator.calculator_tab')}>
            <Calculator />
          </Tab>
          <Tab eventKey="cheatsheet" title={t('page.calculator.cheatsheet_tab')}>
            <CheatSheet />
          </Tab>
        </Tabs>
      </Container>

      <Modal show={showDialog} onHide={handleCloseDialog} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t('page.calculator.copied')}</Modal.Title>
        </Modal.Header>
        <StyledModalBody>
          <FormWrapper>
            <div>
              <img src="/images/logo.png" alt="Logo" height={80} />
            </div>
            <p className="mt-4">{t('page.calculator.desc')}</p>
            <ActionWrapper>
              <Button variant="light" onClick={handleCloseDialog}>
                {t('page.calculator.no_button')}
              </Button>
              <Button variant="primary" className="ml-3" href={process.env.GATSBY_JOIN_URL}>
                {t('page.calculator.yes_button')}
              </Button>
            </ActionWrapper>
          </FormWrapper>
          <Poster>
            <img src="/images/sheet-poster-downloaded.jpg" alt="Poster" style={{ maxWidth: '100%' }} />
          </Poster>
        </StyledModalBody>
      </Modal>
    </Layout>
  );
};

export default CalculatorPage;
