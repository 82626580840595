import styled from 'styled-components';
import React, { FC } from 'react';

import FooterBar from './FooterBar';
import LanguageBar from './LanguageBar';

const Wrapper = styled.footer`
  height: 76px;
`;

const Footer: FC = () => 
  <Wrapper>
    <LanguageBar />
    <FooterBar />
  </Wrapper>
;

export default Footer;
