import styled from 'styled-components';
import React, { FC, ReactElement, useRef } from 'react';
import { Button, Form, FormControl, FormControlProps, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type MoneyInputProps = {
  label: string | ReactElement | undefined;
  prefix?: string;
  canCopy?: boolean;
} & FormControlProps;

const StyledFormControl = styled(FormControl)`
  &&&[readonly] {
    background-color: #fff;
  }
`;

const MoneyInput: FC<MoneyInputProps> = ({ label, prefix, value, canCopy, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const copy = () => {
    if (inputRef.current) {
      inputRef?.current.select();
      document.execCommand('copy');
      let copiedEvent = new Event('mf-copied', { bubbles: true });
      document.dispatchEvent(copiedEvent);
    }
  };

  return (
    <Form.Group className="money-input">
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{prefix}</InputGroup.Text>
        </InputGroup.Prepend>
        <StyledFormControl ref={inputRef} type="number" value={value} {...rest} />
        {canCopy ? (
          <>
            <InputGroup.Append>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-${Date.now()}`}>{t('page.calculator.copy')}</Tooltip>}>
                <Button variant="outline-secondary" onClick={copy}>
                  <FontAwesomeIcon icon={faCopy} width="16" />
                </Button>
              </OverlayTrigger>
            </InputGroup.Append>
          </>
        ) : null}
      </InputGroup>
    </Form.Group>
  );
};

MoneyInput.defaultProps = {
  label: '',
  prefix: 'USD$ ',
  canCopy: false,
};

export default MoneyInput;
