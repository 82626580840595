import styled from 'styled-components';
import React, { FC, FormEvent, useContext, useEffect, useState } from 'react';

import MoneyInput from './MoneyInput';
import { CurrencyContext } from './CurrencySelect';
import { Theme } from '../../utils/theme';
import { calcMargin, calcMarkupPrice, toAmount } from './calc';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${Theme.Breakpoints.MD}) {
    flex-direction: row;
    align-items: flex-end;

    & > .money-input + .money-input {
      margin-left: 0.5rem;
    }
  }

  @media (min-width: ${Theme.Breakpoints.LG}) {
    & > .money-input + .money-input {
      margin-left: 1rem;
    }
  }

  @media (min-width: ${Theme.Breakpoints.XL}) {
    & > .money-input + .money-input {
      margin-left: 1.5rem;
    }
  }
`;

type PercentageCalcProps = {
  labelTotalCost: string;
  labelMarkup: string | React.ReactElement;
  labelPrice: string;
  labelProfit: string;
  labelMargin: string;
  initCost: number;
  initPrice: number;
  price: number;
  setPrice: (price: number) => void;
};

const PercentageCalc: FC<PercentageCalcProps> = ({
  labelTotalCost,
  labelMarkup,
  labelPrice,
  labelProfit,
  labelMargin,
  initCost,
  initPrice,
  price,
  setPrice,
}) => {
  const [cost, setCost] = useState(initCost);
  const [markup, setMarkup] = useState(0);
  const [profit, setProfit] = useState(0);
  const [margin, setMargin] = useState(0);

  const currency = useContext(CurrencyContext);
  const currencyPrefix = `${currency?.isoCode}${currency?.symbol}`;

  useEffect(() => {
    const calcedPrice = calcMarkupPrice(initCost, markup);
    setCost(initCost);
    setPrice(calcedPrice);
    setProfit(toAmount(calcedPrice - initCost));
    setMargin(calcMargin(initCost, calcedPrice));
  }, [initCost]);

  useEffect(() => {
    setPrice(initPrice);
  }, [initPrice]);

  const handleCostChange = (event: FormEvent<HTMLInputElement>) => {
    const amount = toAmount(Number(event.currentTarget.value));
    const calcedPrice = calcMarkupPrice(amount, markup);

    setCost(amount);
    setPrice(calcedPrice);
    setProfit(toAmount(calcedPrice - amount));
    setMargin(calcMargin(amount, calcedPrice));
  };

  const handleMarkupChange = (event: FormEvent<HTMLInputElement>) => {
    const amount = toAmount(Number(event.currentTarget.value));
    const calcedPrice = calcMarkupPrice(cost, amount);

    setMarkup(amount);
    setPrice(calcedPrice);
    setProfit(toAmount(calcedPrice - cost));
    setMargin(calcMargin(cost, calcedPrice));
  };

  return (
    <Wrapper>
      <MoneyInput
        label={labelTotalCost}
        value={cost}
        onChange={handleCostChange}
        prefix={currencyPrefix}
        />
      <MoneyInput
        label={labelMarkup}
        prefix="%"
        value={markup}
        onChange={handleMarkupChange}
        />
      <MoneyInput
        prefix={currencyPrefix}
        label={labelPrice}
        value={price}
        readOnly
        canCopy
        />
      <MoneyInput
        prefix={currencyPrefix}
        label={labelProfit}
        value={profit}
        readOnly
        />
      <MoneyInput prefix="%" label={labelMargin} value={margin} readOnly />
    </Wrapper>
  );
};

export default PercentageCalc;
