/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import styled from 'styled-components';
import React, { FC } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';

import DownloadButton from './DownloadButton';
import sheet from './sheet';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const StyledContainer = styled(Container)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const CheatSheet: FC = () => {
  const chunks = _.chunk(sheet, 33);
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <Row>
        {chunks.map((chunk, index) => (
          <Col key={`col-${index}`}>
            <Table bordered>
              <thead>
                <tr>
                  <th>{t('page.cheat_sheet.markup')}</th>
                  <th>{t('page.cheat_sheet.margin')}</th>
                  <th>{t('page.cheat_sheet.multiplier')}</th>
                </tr>
              </thead>
              <tbody>
                {chunk.map((item, i) => (
                  <tr key={`item-${i}`}>
                    <td>{item.markup}</td>
                    <td>{item.margin}</td>
                    <td>{item.multiplier}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ))}
      </Row>
      <p>{t('page.cheat_sheet.example')}</p>
      <DownloadButton />
    </StyledContainer>
  );
};
export default CheatSheet;
