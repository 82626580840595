/* eslint-disable no-extra-parens */
import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import FormulaTip from './FormulaTip';
import PercentageCalc from './PercentageCalc';
import SumCalc from './SumCalc';
import TextDivider from './TextDivider';

type CreatorCalculatorProps = {
  isDirect: boolean;
};

const CreatorCalculator: FC<CreatorCalculatorProps> = ({ isDirect }) => {
  const { t } = useTranslation();
  const [totalCost, setTotalCost] = useState<number>(0);
  const [wholePrice, setWholePrice] = useState<number>(0);
  const [retailPrice, setRetailPrice] = useState<number>(0);

  return (
    <>
      <TextDivider text={t('page.calculator.production_cost')} />
      <SumCalc
        labelCost={t('page.calculator.mcogs')}
        labelFreight={t('page.calculator.freight')}
        labelImport={t('page.calculator.import')}
        labelTotal={t('page.calculator.cogs')}
        totalCost={totalCost}
        setTotalCost={setTotalCost}
      />

      {isDirect ? (
        <>
          <TextDivider text={t('page.calculator.retail_price')} />
          <PercentageCalc
            labelTotalCost={t('page.calculator.cogs')}
            labelMarkup={
              <FormulaTip
                title={t('page.calculator.markup_formula')}
                content="Retail Price = Total Cost (COGS) * (1 + Markup %)"
                text={t('page.calculator.markup')}
              />
            }
            labelPrice={t('page.calculator.retail_price')}
            labelProfit={t('page.calculator.gross_profit')}
            labelMargin={t('page.calculator.gross_margin')}
            initCost={totalCost}
            initPrice={retailPrice}
            price={retailPrice}
            setPrice={setRetailPrice}
          />
        </>
      ) : (
        <>
          <TextDivider text={t('page.calculator.wholesale_price')} />
          <PercentageCalc
            labelTotalCost={t('page.calculator.cogs')}
            labelMarkup={
              <FormulaTip
                title={t('page.calculator.markup_formula')}
                content="Wholesale Price = Total Cost (COGS) * (1 + Markup %)"
                text={t('page.calculator.markup')}
              />
            }
            labelPrice={t('page.calculator.wholesale_price')}
            labelProfit={t('page.calculator.gross_profit')}
            labelMargin={t('page.calculator.gross_margin')}
            initCost={totalCost}
            initPrice={wholePrice}
            price={wholePrice}
            setPrice={setWholePrice}
          />

          <TextDivider text={t('page.calculator.retail_price')} />
          <PercentageCalc
            labelTotalCost={t('page.calculator.wholesale_price')}
            labelMarkup={
              <FormulaTip
                title={t('page.calculator.markup_formula')}
                content="Retail Price = Wholesale Price * (1 + Markup %)"
                text={t('page.calculator.markup')}
              />
            }
            labelPrice={t('page.calculator.retail_price')}
            labelProfit={t('page.calculator.gross_profit')}
            labelMargin={t('page.calculator.gross_margin')}
            initCost={wholePrice}
            initPrice={retailPrice}
            price={retailPrice}
            setPrice={setRetailPrice}
          />
        </>
      )}
    </>
  );
};

export default CreatorCalculator;
