// import '../../i18n';
import './layout.scss';

import styled from 'styled-components';
// import Banner from '../banner';
// import CookieAcceptance from '../cookie-acceptance';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Footer from '../footer';
import Header from '../header';

// import useLocalStorage from '../../hooks/useLocalStorage';

const Wrapper = styled.main`
  min-height: 100%;
  margin-bottom: -76px;
  padding-bottom: 76px;
`;

const Banner = styled.div`
  background: #5d2f86;
  color: white;
  text-align: center;
  padding: 15px 0;

  p {
    margin: 0;
  }
`;

const CookieText = styled.div`
  a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const Body = styled.div`
  margin-top: 0px;
`;

const Layout: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <CookieConsent
          location="top"
          style={{
            background: '#343a40',
            color: '#fff',
            justifyContent: 'center',
          }}
          cookieName="cookie-acceptance"
          contentClasses="cookie-content"
          enableDeclineButton
          buttonText={t('accept')}
          declineButtonText={t('no_way')}
          declineButtonStyle={{
            background: '#dc3545',
            color: '#fff',
            fontSize: '13px',
            borderRadius: 3,
            marginRight: 0,
          }}
          buttonStyle={{
            background: '#28a745',
            color: '#fff',
            fontSize: '13px',
            borderRadius: 3,
          }}>
          <CookieText
            dangerouslySetInnerHTML={{
              __html: t('cookie_policy'),
            }}
          />
        </CookieConsent>
        <Banner dangerouslySetInnerHTML={{ __html: t('cms:banner') }} />
        <Container>
          <Header />
        </Container>
        <Body>{children}</Body>
      </Wrapper>
      <Footer />
    </>
  );
};
export default Layout;
