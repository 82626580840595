import styled from 'styled-components';
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import { Theme } from '../../utils/theme';

const Wrapper = styled.div`
  background-color: ${Theme.Colors.GRAY};
  color: #fff;
  font-size: 0.8rem;
  padding: 10px 0;
`;
const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkListItem = styled.li`
  display: inline-block;

  + li {
    margin-left: 10px;
  }

  a {
    color: #fff;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Copyright: FC = () => {
  const currentYear = new Date().getFullYear();
  return <div>&copy; {currentYear} Makershaus</div>;
};

const FooterBar: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledContainer>
        <LinkList>
          <LinkListItem>
            <Link to="/about">{t('about', 'About')}</Link>
          </LinkListItem>
          <LinkListItem>
            <Link to="/calculator">{t('calculator', 'Calculator')}</Link>
          </LinkListItem>
          <LinkListItem>
            <Link to="/foundation">{t('foundation', 'Foundation')}</Link>
          </LinkListItem>
          <LinkListItem>
            <Link to="/logos">{t('logos', 'Logos')}</Link>
          </LinkListItem>
          <LinkListItem>
            <Link to="/privacy">{t('privacy', 'Privacy')}</Link>
          </LinkListItem>
          <LinkListItem>
            <Link to="/terms-of-service">{t('terms-of-service', 'Terms of Service')}</Link>
          </LinkListItem>
        </LinkList>
        <Copyright />
      </StyledContainer>
    </Wrapper>
  );
};

export default FooterBar;
