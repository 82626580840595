import styled from 'styled-components';
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

import { Languages } from '../../utils/languages';
import { Theme } from '../../utils/theme';
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next';

const Wrapper = styled.div`
  font-size: 0.75rem;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${Theme.Colors.BORDER};
  padding: 10px 0;
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkListItem = styled.li`
  display: inline-block;

  + li {
    margin-left: 10px;
  }

  a {
    color: ${Theme.Colors.TEXT};
  }
`;

const Title = styled.span`
  font-weight: bold;
`;

const LanguageBar: FC = () => {
  const { t } = useTranslation();
  const { originalPath } = useI18next();

  return (
    <Wrapper>
      <Container>
        <StyledContainer>
          <LinkList>
            <LinkListItem>
              <Title>{t('language')}</Title>
            </LinkListItem>
            {Languages.map(lang => (
              <LinkListItem key={lang.locale}>
                <Link to={originalPath} language={lang.locale}>
                  {lang.name}
                </Link>
              </LinkListItem>
            ))}
          </LinkList>
        </StyledContainer>
      </Container>
    </Wrapper>
  );
};

export default LanguageBar;
